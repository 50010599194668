import React from "react"
import { useEffect, useState } from "react"
import "./style.css"
import logoAltura20 from "../../Images/logo-altura20.png";
import { Col, Container, Row } from "react-bootstrap"

const Footer = () => {
  const [dataRedes, setDataRedes] = useState([]);
  const [dataContact, setContact] = useState([]);

  const getRedes = async () => {
    const res = await fetch(`https://docs.google.com/spreadsheets/d/1BcfTQ-zMB9iL1i3xnTPlpDXMYQAIqbAOmFHSyEMQ8rE/pub?output=csv&gid=1473480731`);
    const data = await res.text();
    const redes = data
      .split("\n")
      .map((row) => {
      const [url] = row.split(",");
      return {url};
    })
    setDataRedes(redes)
  }

  useEffect(() => {
    getRedes();
  }, [])

  const getAbout = async () => {

    const res = await fetch(`https://docs.google.com/spreadsheets/d/1ClY5Kpn7NtnWag56anZhn0YXUw53okRODKj53V-hDZI/pub?output=csv&gid=1435504230`);
    const data = await res.text();
    const about = data
      .split("\n")
      .map((row) => {
      const [url, addres, email, orders1, orders2, calltome] = row.split(",");
      return { url, addres, email, orders1, orders2, calltome };
    })
    setContact(about);
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <footer>
        <Container>
          <Row className="footer-row">
            <Col md={3} sm={5} className='box'>
              <div className="logo">
                <img width={350} src={logoAltura20} alt="" />
              </div>
            </Col>
            <Col md={1} sm={5} className='box'>

            </Col>
            <Col md={4} sm={5} className='box'>
              <h2>Social networks</h2>
              <p className="redes">
                <a href={dataRedes && dataRedes[0]?.url} target="_blank">
                  <span><ion-icon name="logo-facebook"></ion-icon></span>
                </a>
                <a href={dataRedes && dataRedes[1]?.url} target="_blank">
                  <span><ion-icon name="logo-linkedin"></ion-icon></span>
                </a>
              </p>
              {/* <h2>About Us</h2>
              <ul>
                <li>Careers</li>
                <li>Our Stores</li>
                <li>Our Cares</li>
                <li>Terms & Conditions</li>
                <li>Privacy Policy</li>
              </ul> */}
            </Col>
            <Col md={1} sm={5} className='box'>
              {/* <h2>Customer Care</h2>
              <ul>
                <li>Help Center </li>
                <li>How to Buy </li>
                <li>Track Your Order </li>
                <li>Corporate & Bulk Purchasing </li>
                <li>Returns & Refunds </li>
              </ul> */}
            </Col>
            <Col md={3} sm={5} className='box'>
              <h2>Contact Us</h2>
              <ul>
                <li>{dataContact[0]?.addres} </li>
                <li>Email: {dataContact[0]?.email}</li>
                <li>For orders: +{dataContact[0]?.orders1}</li>
                <li>Office: +{dataContact[0]?.orders2}</li>
              </ul>
              <div className="requestExample">
                <a href={"mailto:"+dataContact[0]?.email+"?Subject=I%20request%20a%20sample"}>
                  <span><ion-icon name="mail-outline"></ion-icon> Request a Sample</span>
                </a>
              </div>
              <div className="phonecell">
                <a href={"tel:+"+dataContact[0]?.calltome}>
                  <span>Order Now!</span>
                  <span>{dataContact[0]?.calltome}</span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
    </footer>
  )
}

export default Footer
